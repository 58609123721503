import { getUser } from '@sw-xperts/plugins/directus'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = async () => {
    let isLoggedIn = false

    try {
        const user = await getUser().catch(err => {
            console.error('error check if logged in', err)
        })

        if (user)
            isLoggedIn = true
    }
    catch (err) {
        console.error('error check if logged in', err)
    }

    return isLoggedIn
}
