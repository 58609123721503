import type { DirectusResponse } from '@directus/sdk'
import { authentication, createDirectus, realtime, rest } from '@directus/sdk'

// import { authentication } from '@directus/sdk/auth'
// import type { DirectusResponse } from '@directus/sdk/rest'
// import { rest } from '@directus/sdk/rest'

import { abilitiesByRole } from '@sw-xperts/plugins/directus-casl'
import type { Schema, User } from '@sw-xperts/types'

import ability from '@/plugins/casl/ability'

// const ability = useAppAbility()

export const client = createDirectus<Schema>(import.meta.env.VITE_DIRECTUS_URL)
    .with(rest({ credentials: 'include' }))
    .with(realtime({
        authMode: 'handshake',
        reconnect: {
            retries: 3,
            delay: 1000,
        },
        heartbeat: true,
        debug: true,
    }))
    .with(authentication('session', { credentials: 'include' }))

export const getUser = async (): Promise<DirectusResponse<User> | null> => {
    try {
        // Try refreshing the client session
        await client.refresh()
    }
    catch (error) {
        console.error('Error refreshing client:', error)

        return null // Return null if refreshing fails, so you can handle this case
    }

    let user: DirectusResponse<User> | null = null
    try {
        // Make the API request to get the user data
        user = await client.request<DirectusResponse<User>>(
            () => {
                return {
                    method: 'GET',
                    path: '/users/me',
                    params: {
                        fields: [
                            'id',
                            'email',
                            'role',
                            'avatar',
                            'first_name',
                            'last_name',
                            'status',
                        ],
                    },
                }
            },
        )
    }
    catch (error) {
        console.error('Error fetching user data:', error)

        return null // Return null in case of an API request failure
    }

    try {
        // If the user has a role and corresponding abilities, update abilities
        if (user?.role && abilitiesByRole?.[user.role]) {
            const userAbilities = abilitiesByRole[user.role]

            localStorage.setItem('userAbilities', JSON.stringify(userAbilities))
            ability.update(userAbilities)
        }
    }
    catch (error) {
        console.error('Error updating user abilities:', error)
    }

    return user
}

// export const userData = ref<User | null>(null);
export const userDataStore = {
    get: async () => {
        const user = ref<User | null>(null)

        user.value = await getUser().catch(err => {
            console.error('getUser Error', err)
        })

        return user
    },
    set: (value = null) => {
        if (value)
            localStorage.setItem('userData', JSON.stringify(value))

        else if (userData.value)
            localStorage.setItem('userData', JSON.stringify(userData.value))
    },
}

export const userData = ref<User | null>(null)
