import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from './utils'
import routes from '~pages'
import { canNavigate } from '@layouts/plugins/casl'

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_BASE_URL),
    routes: [
        ...setupLayouts(routes),
    ],
})

const isAllowedExternalUrl = url => {
    try {
    // Try creating a URL object; this will fail if it's not a full URL
        const parsedUrl = new URL(url)

        // Check if the domain is 'sw-xperts.com' or subdomains of it
        return parsedUrl.hostname.endsWith('sw-xperts.com')
    }
    catch (e) {
    // If URL constructor fails, it means it's likely a relative path, so no redirection
        return false
    }
}

router.beforeEach(async to => {
    const isLoggedIn = await isUserLoggedIn()

    // if not logged in, clear local storage
    if (!isLoggedIn)
        localStorage.clear()

    if (to.name === 'getting-logged-in') {
        if (isLoggedIn) {
            if (to.query.to) {
                const redirectUrl = `${to.fullPath}`.replace('/getting-logged-in?to=', '')
                if (isAllowedExternalUrl(redirectUrl)) {
                    window.location.href = redirectUrl

                    window.setTimeout(() => {
                        window.close()
                    }, 5000)

                    return false
                }
                else {
                    // Redirect to `to` query if exist or redirect to index route
                    return `${to.query.to}${to.hash}`
                }
            }
            else { return { name: 'index' } }
        }
        else {
            return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
        }
    }
    else if (canNavigate(to)) {
        if (to.meta.redirectIfLoggedIn && isLoggedIn)
            return '/'
    }
    else {
        if (isLoggedIn)
            return { name: 'not-authorized' }
        else
            return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
    }
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

export default router
